import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    message: "",
    postOrderBody: {},
    recipient: {},
    signClick: false,
    signature: "",
    readOrderInfo: {},
    orderUpdateLoading: false,
    orderPostSuccess: false,
};

const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setMessage: (state, action) => {
            state.message = action.payload;
        },
        setPostOrderBody: (state, action) => {
            state.postOrderBody = action.payload;
        },
        setRecipient: (state, action) => {
            state.recipient = action.payload;
        },
        setSignClick: (state, action) => {
            state.signClick = action.payload;
        },
        setSignature: (state, action) => {
            state.signature = action.payload;
        },
        updateReadOrderInfo: (state, action) => {
            state.readOrderInfo = action.payload;
        },
        updateOrderUpdateLoading: (state, action) => {
            state.orderUpdateLoading = action.payload;
        },
        updateOrderPostSuccess: (state, action) => {
            state.orderPostSuccess = action.payload;
        }
    },
});

export const {
    setMessage,
    setPostOrderBody,
    setRecipient,
    setSignClick,
    setSignature,
    updateReadOrderInfo,
    updateOrderUpdateLoading,
    updateOrderPostSuccess,
} = commonSlice.actions;

export default commonSlice.reducer;
