import React, { useEffect, useState } from "react";
import CardContent from '@mui/material/CardContent';
import { etherscanLink } from "../../utils";
import { FiExternalLink } from "react-icons/fi";
import "../mint.css";

export const DoneView = ({ txHash, message }) => {
    const [etherscan, setEtherscan] = useState("https://etherscan.io/tx/");
    useEffect(() => {
        const fetchChainId = async () => {
            const es = await etherscanLink();
            setEtherscan(es);
        }
        fetchChainId();
    }, [])
    return (
        <CardContent>
            <div className="cardWrapper">
                <h1>
                    {message}
                </h1>
                {txHash && <a
                    target="_blank"
                    rel="noreferrer"
                    href={etherscan + txHash}
                    className="link-button"
                >
                    Etherscan <FiExternalLink />
                </a>}
            </div>
        </CardContent>
    )
}