import { combineReducers } from "redux";

// common reducers
import modalFormReducer from "../components/ModalForm/ModalFormSlice";
import userModalReducer from "../components/UserModal/UserModalSlice";
import ordersModalReducer from "../components/OrdersModal/OrdersModalSlice";
import commonReducer from "../components/common/commonSlice";

const rootReducer = combineReducers({
  // Define a top-level state field named `todos`, handled by `todosReducer`
  modalForm: modalFormReducer,
  userModal: userModalReducer,
  ordersModal: ordersModalReducer,
  common: commonReducer,
});

export default rootReducer;
