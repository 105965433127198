import { useDispatch, useSelector } from "react-redux";
import "./OrderCard.css";
import * as _ from "lodash";
import { FaEdit } from "react-icons/fa";
import { updateReadOrderInfo } from "../common/commonSlice";

const readAttributefrom = (order) => {
    const orderId = _.get(order, "id", "N/A");
    const name = _.get(order, "name", "N/A");
    const amount = _.get(order, "amount", 0);
    const recipient = _.get(order, "recipient", "");
    const street = _.get(recipient, "street", "");
    const city = _.get(recipient, "city", "");
    const state = _.get(recipient, "state", "");
    const country = _.get(recipient, "country", "");
    const zipcode = _.get(recipient, "zipcode", "");
    const fistName = _.get(recipient, "first_name", "");
    const lastName = _.get(recipient, "last_name", "");
    const shippingAddress = `${street} ${city} ${state} ${country} ${zipcode}`;
    const shipTo = `${fistName} ${lastName}`;
    const phone = _.get(recipient, "phone", "");
    const imageUrl = _.get(order, "imageUrl", "https://via.placeholder.com/150");
    const transaction_hash = _.get(order, "transaction_hash", "");
    const status = _.get(order, "status", "N/A");
    return { orderId, name, amount, shippingAddress, transaction_hash, shipTo, status, phone, imageUrl };
}
export const OrderCard = ({ order, handleAddShip }) => {
    const chain = useSelector(state => state.userModal.chainId);
    const { orderId, name, amount, shippingAddress, transaction_hash, shipTo, status, phone, imageUrl } = readAttributefrom(order);

    const dispatch = useDispatch();

    const handleAddShippingInfo = () => {
        console.log("add shipping info");
        dispatch(updateReadOrderInfo(order))
        handleAddShip(order.rawOrder);
    }
    return (
        <div className="order-card">
            {/* <div className="order-card-edit">
                <button onClick={handleAddShippingInfo}><FaEdit size={20} /></button>
            </div> */}
            <h1>
                Order #{orderId}
            </h1>
            <div className="order-card-content">
                <div className="order-card-item">
                    <h2>Item</h2>
                    <a href={`${_.get(chain, ["blockExplorers", "default", "url"])}/tx/${transaction_hash}`} target="_blank" rel="noreferrer">
                        <p>{name}</p>
                    </a>
                </div>
                <div className="order-card-item">
                    <h2>Amount</h2>
                    <p>{amount}</p>
                </div>
                <div className="order-card-item">
                    <h2>Status</h2>
                    <p>{status}</p>
                </div>
                <div className="order-card-item">
                    <h2>Ship to</h2>
                    <p>{shipTo}</p>
                </div>
                <div className="order-card-item">
                    <h2>Shipping address</h2>
                    <p>{shippingAddress}</p>
                </div>
                <div className="order-card-item">
                    <h2>Contact</h2>
                    <p>{phone}</p>
                </div>
            </div>
        </div>
    )
}