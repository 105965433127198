import { setButtonText } from '../utils';
import { getAttrInfo, getMintInfoFromLoader, mintDispatchHub } from '../dataLoader/dataLoader';

const checkSymbol = "mint-button"

export const updateMintButton = () => {
    const mintButtons = [
        ...document.querySelectorAll(`[${checkSymbol}]`)
    ]
    if (mintButtons) {
        console.log(mintButtons);
        mintButtons.forEach((mintButton) => {
            // get information from div attribute
            const infoFromAttr = getAttrInfo(mintButton, checkSymbol);
            mintButton.href = "#"
            mintButton.onclick = async () => {
                const initialBtnText = mintButton.textContent;
                setButtonText(mintButton, "Loading...")
                // get info from data loader
                const infoFromLoader = await getMintInfoFromLoader(infoFromAttr);
                // push info and dispath form open. 
                // console.log("infoFromLoader", infoFromLoader);
                mintDispatchHub(infoFromLoader);
                // After dispatch
                setButtonText(mintButton, initialBtnText)
            }
        })
    }
}