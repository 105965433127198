import { store } from "../app/store";
import { getProxySupply } from "../contract_access_object/boxcat/readerCao";
import { getProxyMintedByUser, getProxyMintInfo } from "../contract_access_object/erc721blank/reader";
import { getProxyMinted, getProxyName } from "../contract_access_object/readerCao";

export const getNftBaseInfo = async (info) => {
    if (!info.contractAddr) {
        throw new Error("Invalid contract address.");
    }
    const userAddr = store.getState().userModal.walletAddress;
    const contractAddr = info.contractAddr;
    const name = await getProxyName(contractAddr);
    const mintInfo = await getProxyMintInfo(contractAddr);
    const mintedByUser = Number(await getProxyMintedByUser(contractAddr, userAddr));
    var startTime = "";
    var endTime = "";
    var maxAvailable = "";
    var purchaseLimit = "";
    var price = "";
    if (mintInfo) {
        startTime = mintInfo.startTime;
        endTime = mintInfo.endTime;
        maxAvailable = mintInfo.maxAvailable;
        purchaseLimit = mintInfo.purchaseLimit - mintedByUser;
        price = mintInfo.price;
    }
    const minted = Number(await getProxyMinted(contractAddr));
    const supply = Number(await getProxySupply(contractAddr));

    const res = {
        ...info,
        name,
        supply,
        minted,
        startTime,
        endTime,
        maxAvailable,
        purchaseLimit,
        price,
        selections: Array.from({ length: purchaseLimit }, (_, i) => i + 1),
    }
    return res;
}