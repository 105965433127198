/**
 * This is a collection of functions to read from contract, i.e. Contract Access Object
 */

import { callViewFunction } from "../cao";

export const getProxySupply = async (proxyAddr) => {
  const res = await callViewFunction(proxyAddr, "collectionSize", []);
  return res;
}

export const getProxyAvailable = async (proxyAddr, user, index) => {
  const res = await callViewFunction(proxyAddr, "available", [user, index]);
  return res;
}
/**
 * Returns proxy specific data.
 * TODO: fill XX with meaningful wording, e.g. getProxyPublicMintTime
 */
export const getProxyXX = async (proxyAddr) => { };
