import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from "@mui/material/DialogTitle";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { AiOutlineCloseCircle } from "react-icons/ai";

import "../commonModal.css";

import { BurnView } from "./BurnView";
import { DoneView } from "../../common/doneView";
import { ErrorView } from "../../common/errorView";
import { isMobile } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { closeEcommerceBurnForm } from "../ModalFormSlice";
import { PowerBy } from "../../common/PowerBy";
import { AddShippingView } from "../../common/AddShippingView";
import { updateOrderPostSuccess } from "../../common/commonSlice";

const titleClass = { width: "100%", padding: "0px" }

export const EcommerceMintModal = () => {
    const isOpen = useSelector((state) => state.modalForm.ecommerceBurnFormOpen);
    const orderPostSuccess = useSelector((state) => state.common.orderPostSuccess);
    const dispath = useDispatch();

    const [step, setStep] = useState(1)
    const [txHash, setTxHash] = useState(undefined);
    const handleClose = () => {
        dispath(closeEcommerceBurnForm());
        setStep(1);
    }

    const handleSuccess = () => {
        setStep(4);
    }

    useEffect(() => {
        if (orderPostSuccess) {
            handleSuccess();
            dispath(updateOrderPostSuccess(false))
        }
    }, [orderPostSuccess])

    const cardClasses = isMobile() ? { minWidth: 275 } : { minWidth: 475 };

    return (
        <>
            <Dialog
                PaperProps={{
                    style: { borderRadius: 20, fontFamily: `"Roboto","Helvetica","Arial",sans-serif` }
                }}
                open={isOpen}
            >
                <DialogTitle style={titleClass}>
                    <div className="common-title">
                        <h1>
                            Redeem Physical Items
                        </h1>
                        <button onClick={handleClose} style={{ border: "none", background: "none", cursor: "pointer", width: "auto", height: "auto" }}>
                            <AiOutlineCloseCircle size={25} />
                        </button>
                    </div>
                </DialogTitle>
                <Card sx={cardClasses}>
                    <CardContent>

                        {(step === 1) &&
                            <BurnView
                                setStep={setStep}
                                setTxHash={setTxHash}
                            />}
                        {(step === 2) && <AddShippingView handleCancel={handleClose} />}
                        {(step === 4) && <DoneView txHash={txHash} message={"Congratuations! You successfully redeemed your physical items!"} />}
                        {(step === 5) && <ErrorView message={"Sorry, you don't have enough token to forge."} discord={"https://t.co/vLz3ZHfBDv"} />}
                    </CardContent>
                    <PowerBy />
                </Card>
            </Dialog>
        </>


    )
}