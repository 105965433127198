import { callViewFunction } from "../cao";

export const getProxySupply = async (proxyAddr) => {
    const res = await callViewFunction(proxyAddr, "collectionSize", []);
    return res;
}

export const getProxyMintInfo = async (proxyAddr) => {
    const res = await callViewFunction(proxyAddr, "mintInfo", []);
    return res;
}

export const getProxyMintedByUser = async (proxyAddr, userAddr) => {
    const res = await callViewFunction(proxyAddr, "numberMinted", [userAddr]);
    return res;
}