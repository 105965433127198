import React, { useEffect, useState } from "react";
import CardContent from '@mui/material/CardContent';
import "../mint.css";
import { convertWeiToETH } from "../../utils";

export const BeforeMintView = ({ info, setStep }) => {
    const [countDown, setCountDown] = useState("Not set");
    const [intervalX, setIntervalX] = useState(null);
    const countDownTrigger = (startTime) => {

        if (intervalX) {
            clearInterval(intervalX);
            setIntervalX(null);
        }
        // Update the count down every 1 second
        var x = setInterval(function () {

            // Get today's date and time
            var now = new Date().getTime();

            // Find the distance between now and the count down date
            var distanceToStart = Number(startTime) * 1000 - now;

            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distanceToStart / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distanceToStart % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distanceToStart % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distanceToStart % (1000 * 60)) / 1000);


            const count = days + "d " + hours + "h " + minutes + "m " + seconds + "s ";
            setCountDown(count);
            setIntervalX(x);
            if (distanceToStart < 0) {
                clearInterval(x);
                setCountDown("mint on");
            }
        }, 1000);
    }
    useEffect(() => {
        if (info.startTime && info.startTime !== '0') {
            var now = new Date().getTime();
            if (Number(info.startTime) * 1000 - now < 0) {
                setStep(2);
            }
            else {
                countDownTrigger(info.startTime);
            }

        }
    }, [info.startTime])

    return (
        <CardContent>
            <div className="cardWrapper">
                {(countDown !== "Not set") &&
                    <h2>
                        Mint will start in: {countDown}
                    </h2>
                }
                {(countDown === "Not set") &&
                    <h2>
                        Mint is not set yet. Please keep checking back.
                    </h2>
                }
                {info.price && (countDown !== "Not set") &&
                    <h2>
                        Mint price: {convertWeiToETH(info.price)} eth per item
                    </h2>
                }
            </div>
        </CardContent>
    )
}