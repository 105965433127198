import { store } from "../app/store";
import { getProxyBalanceByTokenID, getProxyName } from "../contract_access_object/readerCao";

export const getEcommerceBurnInfo = async (info) => {
    if (!info.contractAddr) {
        throw new Error("Invalid contract address.");
    }
    const userAddr = store.getState().userModal.walletAddress;
    const name = await getProxyName(info.contractAddr);
    const owned = Number(await getProxyBalanceByTokenID(info.contractAddr, userAddr, info.tokenId));
    const res = {
        ...info,
        name,
        owned,
        userAddr,
    }
    return res;
}