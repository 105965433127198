import React, { useState, useEffect } from "react";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import { convertWeiToETH } from '../../../utils'
import { MOCK } from "../../../contract_access_object/constants";
import { useStyles } from "../../muiStyle";

import "../../mint.css";
import { decodeLogsBy, getTransactionReceiptMined } from "../../../componentHelper";
import { getMintButtonText } from "../../../dataLoader/dataLoader";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { setErc721BlankMint } from "../../../contract_access_object/erc721blank/writerCao";
import { setPostOrderBody } from "../../common/commonSlice";

export const MintView = ({ setStep, setTxHash }) => {
    const info = useSelector((state) => state.modalForm.info);
    const dispatch = useDispatch();
    const walletAddress = useSelector((state) => state.userModal.walletAddress);
    const chain = useSelector((state) => state.userModal.chainId);
    const { enqueueSnackbar } = useSnackbar();

    const [quantity, setQuantity] = useState(1);
    const [txnStatus, setTxnStatus] = useState("");
    const finalPrice = ((info.price ? info.price : 0) * quantity).toString();
    const selections = info.selections ? info.selections : [1, 2, 3, 4, 5];
    const classes = useStyles();
    useEffect(() => {
        if (txnStatus === "DONE") {
            if (info.addressEnabled) {
                setStep(3);
            }
            else {
                setStep(4);
            }
        }
    },);
    useEffect(() => {
        if (selections.length === 0) {
            setStep(5);
        }
    }, [selections]);
    const handleChange = (event) => {
        setTxnStatus("");
        setQuantity(event.target.value);
    };
    const handleMint = async () => {
        setTxnStatus("Wait for approval");
        try {
            const txn = MOCK ? "test" : await setErc721BlankMint(
                info.contractAddr,
                quantity,
                finalPrice
            );
            if (txn) {
                setTxnStatus("Loading");
                const txnRes = await getTransactionReceiptMined(txn.hash);
                const afterInfo = await decodeLogsBy(txnRes.logs, info.logicAddr, "Transfer");
                var mintedTokenId = [];
                for (let i = 0; i < afterInfo.length; i++) {
                    if (afterInfo[i]) {
                        mintedTokenId.push(Number(afterInfo[i].tokenId));
                    }
                }
                setTxHash(txn.hash);
                if (info.addressEnabled) {
                    setStep(3);
                    const redeemed_tokens = [{
                        receipt_address: "",
                        receipt_token_ids: mintedTokenId,
                        redeemed_number: quantity,
                        token_id: 0
                    }]
                    dispatch(setPostOrderBody({
                        transaction_hash: txn.hash,
                        redeemed_tokens,
                        status: "submitted",
                        wallet_address: walletAddress,
                        chain_id: chain.id,
                        proxy_address: info.contractAddr,
                    }))
                }
                else {
                    setStep(4);
                }
            }
            else {
                setTxnStatus("");
            }
        }
        catch (e) {
            setTxnStatus("");
            const msg = e.message;
            const regex = /reason="(.*?)",/;
            const found = msg.match(regex);
            if (found) {
                enqueueSnackbar(found[1], {
                    variant: "error",
                });
            }
            else {
                enqueueSnackbar(msg.split("(")[0], {
                    variant: "error",
                });
            }
        }
    }
    return (
        <div className="cardWrapper">
            <div className="select-wrapper">
                <div className="product-tag">
                    <h1>{info.name}</h1>
                    {info.supply && info.minted &&
                        <h2>{Number(info.supply) - Number(info.minted)} of {info.supply} available</h2>
                    }
                    {!info.supply && info.minted &&
                        <h2>{Number(info.minted)} items minted</h2>
                    }
                </div>
                <FormControl sx={{ m: 1, minWidth: 80 }} className={classes.root} size="small">
                    <Select
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        value={quantity}
                        onChange={handleChange}
                    >
                        {selections.map((element, index) => (
                            <MenuItem value={element} key={index}>{element}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className="price-tag">
                <div className="small">Subtotal:</div>
                <div className="bold">{convertWeiToETH((finalPrice).toString())} ETH</div>
            </div>
            <button disabled={txnStatus !== ""} className="common-button" variant="outlined" onClick={handleMint}>
                {txnStatus ? txnStatus : getMintButtonText(info.type)}
                {txnStatus === "Loading" && <CircularProgress color="inherit" size="1rem" />}
            </button>
        </div>
    )
}