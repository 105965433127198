import { APP_TYPES, APP_TYPE_ECOMMERCE, APP_TYPE_BOXCAT, APP_TYPE_BASE_NFT, API_SQL_DB, IMAGE_PLACEHOLDER } from "../constants";
import { getProxyAvailable } from "../contract_access_object/boxcat/readerCao";
import {
    getProxyName,
    getProxyPriceByTokenID,
    getProxyIsMintOnByTokenID,
    getProxyMintedByTokenID,
    getProxySupplyByTokenID,
    getProxyMinted,
} from "../contract_access_object/readerCao";
import { getProxyMintInfo, getProxySupply } from "../contract_access_object/erc721blank/reader";
import { store } from "../app/store";
import { openBoxCatMintForm, openEcommerceBurnForm, openMintForm, updateInfo } from "../components/ModalForm/ModalFormSlice";
import { convertIPFSToGateway, setAddr } from "../utils";
import { getNftBaseInfo } from "./dataLoaderNftBase";
import { initProxyContract } from "../wallet/wallet";
import { getBoxCatInfo } from "./dataLoaderBoxcat";
import { getEcommerceBurnInfo } from "./dataLoaderEcommerce";
import fetch from "node-fetch";
import * as _ from "lodash";
import { getUri } from "../contract_access_object/eStore/readerCao";
import { setOrderRawCache } from "../components/OrdersModal/OrdersModalSlice";

export const getContractInfoRecur = async (contract, tokenId) => {
    // const mintInfo = await getContractInfo(contract, tokenId);
    // console.log("getContractInfoRecur", mintInfo);
    // setMintInfo(mintInfo);
    // if (!recursiveCheck) {
    //     recursiveCheck = setInterval(async function () {
    //         const mintInfo = await getContractInfo();
    //         console.log("getContractInfoRecur", mintInfo);
    //         setMintInfo(mintInfo);
    //     }, MINTED_CHECK_CAP);
    // }
}

export const getEcommerceContractInfo = async (contractAddr, tokenId) => {
    const name = await getProxyName(contractAddr);
    const isMintOn = await getProxyIsMintOnByTokenID(contractAddr, tokenId);
    const price = await getProxyPriceByTokenID(contractAddr, tokenId);
    const minted = await getProxyMintedByTokenID(contractAddr, tokenId);
    const supply = await getProxySupplyByTokenID(contractAddr, tokenId);
    const info = {
        name,
        isMintOn,
        price: price,
        minted,
        supply,
        tokenId,
        type: APP_TYPE_ECOMMERCE,
    }
    return info
}

export const getBoxCat721ContractInfo = async (contractAddr, user) => {
    const name = await getProxyName(contractAddr);
    const price = "0";
    const minted = await getProxyMinted(contractAddr);
    const available = await getProxyAvailable(contractAddr, user);

    const info = {
        name,
        price,
        minted,
        selections: Array.from({ length: available }, (_, i) => i + 1),
        type: APP_TYPE_BOXCAT,
    }
    return info
}

export const getBlank721ContractInfo = async (contractAddr, user) => {
    const name = await getProxyName(contractAddr);
    const mintInfo = await getProxyMintInfo(contractAddr);
    var startTime = "";
    var endTime = "";
    var maxAvailable = "";
    var purchaseLimit = "";
    var price = "";
    if (mintInfo) {
        startTime = mintInfo.startTime;
        endTime = mintInfo.endTime;
        maxAvailable = mintInfo.maxAvailable;
        purchaseLimit = mintInfo.purchaseLimit;
        price = mintInfo.price;
    }
    const minted = await getProxyMinted(contractAddr);
    const supply = await getProxySupply(contractAddr);

    const info = {
        name,
        supply,
        minted,
        startTime,
        endTime,
        maxAvailable,
        purchaseLimit,
        price,
        selections: Array.from({ length: purchaseLimit }, (_, i) => i + 1),
        type: APP_TYPE_BASE_NFT,
    }
    return info
}

export const getContractName = async (contractAddr) => {
    const name = await getProxyName(contractAddr);
    const info = {
        name,
    }
    return info;
}

export const getMintButtonText = (info) => {
    var res = "MINT";
    switch (info) {
        case APP_TYPE_BOXCAT:
            res = "MINT";
            break;
        case APP_TYPE_ECOMMERCE:
            res = "BUY NOW";
            break;
        case APP_TYPE_BASE_NFT:
            res = "MINT";
            break;
        default:
            res = "MINT";
            break;
    }
    return res;
}



export const getAttrInfo = (button, buttonName) => {
    var type = button.getAttribute(buttonName);
    var info;
    const contractAddrAttr = button.getAttribute('contractaddress');
    const logicAddrAttr = button.getAttribute('logicaddress');
    const addressEnabled = button.getAttribute('addressenable');
    const [contractAddr, logicAddr] = setAddr(contractAddrAttr, logicAddrAttr);
    if (!APP_TYPES.includes(type)) {
        switch (logicAddr) {
            case "0xF8A7a09821bE27CD4f6394E1a82646eD4e6c88aB": {
                type = APP_TYPE_ECOMMERCE;
                break;
            }
            case "0xe496107B2D6a5A4fD771461e01D422E0C1c9194C":
            case "0xFd2A688AD995C39e363bfa74D84CC9BF933e94f5": {
                type = APP_TYPE_BASE_NFT;
                break;
            }
            default:
                return null
        }
    }
    switch (type) {
        case APP_TYPE_BASE_NFT:
        case APP_TYPE_BOXCAT:
            {
                info = {
                    contractAddr,
                    logicAddr,
                    type,
                }
                break;
            }
        case APP_TYPE_ECOMMERCE:
            {
                const tokenId = button.getAttribute("tokenId");
                info = {
                    contractAddr,
                    logicAddr,
                    type,
                    tokenId,
                }
                break;
            }
        default:
            return null;
    }
    info = {
        ...info,
        addressEnabled: addressEnabled ? true : false,
    }

    return info;
}

export const getMintInfoFromLoader = async (info) => {
    // console.log("getMintInfoFromLoader", info);
    var res;
    if (info && ("type" in info)) {
        if (!store.getState().userModal.signer) {
            throw new Error("No signer define");
        }
        await initProxyContract(info.contractAddr, info.logicAddr, store.getState().userModal.signer);
        switch (info.type) {
            case APP_TYPE_BASE_NFT: {
                res = await getNftBaseInfo(info);
                break;
            }
            case APP_TYPE_BOXCAT: {
                res = await getBoxCatInfo(info);
                break;
            }
            default:
                throw new Error("Cannot find correct type!");
        }
    }
    else {
        throw new Error("No type info define", info);
    }

    return res;
}

export const getBurnInfoFromLoader = async (info) => {
    var res;
    if (info && ("type" in info)) {
        await initProxyContract(info.contractAddr, info.logicAddr, store.getState().userModal.provider);
        switch (info.type) {
            case APP_TYPE_ECOMMERCE: {
                res = await getEcommerceBurnInfo(info);
                break;
            }
            default:
                throw new Error("Cannot find correct type!");
        }
    }
    else {
        throw new Error("No type info define", info);
    }

    return res;
}

export const mintDispatchHub = (info) => {
    // console.log("mintDispatchHub", info);
    if (info.type) {
        switch (info.type) {
            case APP_TYPE_BASE_NFT: {
                store.dispatch(openMintForm());
                store.dispatch(updateInfo(info));
                break;
            }
            case APP_TYPE_BOXCAT: {
                store.dispatch(openBoxCatMintForm());
                store.dispatch(updateInfo(info));
                break;
            }
            default:
                throw new Error("Cannot find correct type!");
        }
    }
    else {
        throw new Error("No type info define", info);
    }

}

export const burnDispatchHub = (info) => {
    if (info.type) {
        // const dispath = useDispatch();
        switch (info.type) {
            case APP_TYPE_ECOMMERCE: {
                store.dispatch(openEcommerceBurnForm());
                store.dispatch(updateInfo(info));
                break;
            }
            default:
                throw new Error("Cannot find correct type!");
        }
    }
    else {
        throw new Error("No type info define", info);
    }

}

export const getAttrText = (node, symbol) => {
    const attr = node.getAttribute(symbol);
    try {
        const info = JSON.parse(attr);
        return info;
    }
    catch {
        return attr;
    }
}


export const getOrders = async (walletAddress, signature, contractAddr, chainId, forceFetchData = false) => {
    const hash = `${walletAddress}${contractAddr}${chainId}`;
    if (store.getState().ordersModal.orderRawCache[hash] && !forceFetchData) {
        console.log("getOrders from cache");
        return store.getState().ordersModal.orderRawCache[hash];
    }
    else {
        const orderRes = await fetch(`${API_SQL_DB}order?user=${walletAddress}&signer=${walletAddress}&app=${contractAddr}&chain=${chainId}&sig=${signature}&expanded=true`, {
            method: "GET",
            headers: {
                "Cache-Control": "no-cache",
            }
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    throw new Error(`Network response error. ${response.status}`)
                }
            })
            .then(async (response) => {
                const orders = _.get(response, ["orders"]);
                return (orders);
            })
            .catch((error) => {
                console.error(error);
            });
        store.dispatch(setOrderRawCache({ [hash]: orderRes }));
        return orderRes;
    }
}

let metaData = {};
export const getMetaData = async (contractAddr, tokenId, type) => {
    if (contractAddr in metaData) {
        if (tokenId in metaData[contractAddr]) {
            console.log("getMetaData from cache", contractAddr, tokenId);
            return metaData[contractAddr][tokenId];
        }
    }
    var baseURI = "";
    if (type === "1155") {
        try {
            baseURI = await getUri(contractAddr, tokenId)
        } catch (e) {
            throw new Error("Cannot get baseURI");
        }
    }
    const finalUri = convertIPFSToGateway(baseURI);
    console.log("getMetaData from ipfs", finalUri);
    if (!finalUri) {
        return {
            name: `Token ID: ${tokenId}`,
            imageUrl: IMAGE_PLACEHOLDER,
        }
    }
    const jsonInfo = await fetch(finalUri, {
        method: "GET",
    })
        .then((response) => response.json())
        .then(async (response) => {
            console.log(response);
            return response;
        })
        .catch((error) => {
            console.error(error);
        });

    if (contractAddr in metaData) {
        var tmp = metaData[contractAddr];
        if (tokenId in tmp) {
            tmp[tokenId] = {
                name: jsonInfo.name,
                imageUrl: convertIPFSToGateway(jsonInfo.image)
            };
        }
        else {
            tmp = {
                ...tmp,
                [tokenId]: {
                    name: jsonInfo.name,
                    imageUrl: convertIPFSToGateway(jsonInfo.image)
                }
            }
            metaData[contractAddr] = tmp;
        }
    }
    else {
        metaData[contractAddr] = {
            [tokenId]: {
                name: jsonInfo.name,
                imageUrl: convertIPFSToGateway(jsonInfo.image)
            }
        }
    }
    console.log("metaData", metaData);
    return {
        name: jsonInfo.name,
        imageUrl: convertIPFSToGateway(jsonInfo.image)
    }
}

export const formatOrders = async (contractAddr, orders) => {
    const uniqueTxns = [];
    var ordersReverse = [...orders];
    const orderFiltered = ordersReverse.reverse().filter((order) => {
        const transaction_hash = _.get(order, ["transaction_hash"], "");
        const isDuplicate = uniqueTxns.includes(transaction_hash);

        if (!isDuplicate) {
            uniqueTxns.push(transaction_hash);
            return true;
        }
        return false;
    });

    const uniqueTokenIds = [];
    const unzipOrders = await Promise.all(orderFiltered.map(async (order) => {
        const redeemedTokens = _.get(order, ["redeemed_tokens"], []);
        if (redeemedTokens.length > 0) {
            const amount = redeemedTokens[0].redeemed_number;
            const tokenId = redeemedTokens[0].token_id;
            const isDuplicate = uniqueTokenIds.includes(tokenId);
            if (!isDuplicate) {
                uniqueTokenIds.push(tokenId);
            }
            //TODO: get metadata from ipfs in more generic way
            return {
                ...order,
                tokenId,
                amount,
            }
        }
        else {
            return order;
        }
    }));

    const tokenIdToMetaData = {};
    await Promise.all(uniqueTokenIds.map(async (tokenId) => {
        const metaData = await getMetaData(contractAddr, tokenId, "1155");
        const name = _.get(metaData, ["name"], "");
        const imageUrl = _.get(metaData, ["imageUrl"], "");
        console.log("metaData", metaData);
        tokenIdToMetaData[tokenId] =
        {
            name,
            imageUrl,
        }
    }));

    return unzipOrders.map((order) => {
        console.log("order", order, tokenIdToMetaData);
        const tokenId = _.get(order, ["tokenId"], "");
        const name = _.get(tokenIdToMetaData, [tokenId, "name"], "");
        const imageUrl = _.get(tokenIdToMetaData, [tokenId, "imageUrl"], "");
        return {
            ...order,
            name,
            imageUrl,
        }
    });
}

export const postOrder = async (body, signer, chainId, signature, handleSuccess) => {
    if (body && signer && chainId && signature) {
        await fetch(`${API_SQL_DB}order?signer=${signer}&chain=${chainId}&sig=${signature}`, {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    throw new Error(`Network response error. ${response.status}`)
                }
            })
            .then(response => {
                console.log(response);
                handleSuccess();
            })
            .catch(e => {
                console.error(e);
                const msg = e.message;
                throw new Error(msg);
            });
    }
    else {
        throw new Error("Missing body, signer, app, chainId, or signature");
    }
}

export const putOrder = async (orderId, body, signer, chainId, signature, handleSuccess) => {
    if (body && signer && chainId && signature) {
        await fetch(`${API_SQL_DB}order/${orderId}?signer=${signer}&chain=${chainId}&sig=${signature}`, {
            method: "PUT",
            body: JSON.stringify(body),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then(response => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    throw new Error(`Network response error. ${response.status}`)
                }
            })
            .then(response => {
                console.log(response);
                handleSuccess();
            })
            .catch(e => {
                console.error(e);
                const msg = e.message;
                throw new Error(msg);
            });
    }
    else {
        throw new Error("Missing body, signer, app, chainId, or signature");
    }
}

export const formatReadOrder = (order) => {
    // const shipTo = _.get(order, ["first_name"], "") + " " + _.get(order, ["last_name"], "");
    // const street = _.get(order, ["street"], "");
    // const city = _.get(order, ["city"], "");
    // const state = _.get(order, ["state"], "");
    // const zipcode = _.get(order, ["zipcode"], "");
    // const country = _.get(order, ["country"], "");
    // const shippingAddress = street + " " + city + " " + state + " " + zipcode + " " + country;
    // const txn = _.get(order, ["transaction_hash"], "");
    // const status = _.get(order, ["status"], "");
    // const redeemedTokens = _.get(order, ["redeemed_tokens"], []);
    // const orderId = _.get(order, ["order_id"], "");
    // const phone = _.get(order, ["phone"], "");
    // return {
    //     shipTo,
    //     txn,
    //     status,
    //     shippingAddress,
    //     orderId,
    //     phone,
    //     redeemedTokens,
    //     rawOrder: order
    // }
    return order;
}
