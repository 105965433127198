import React, { useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from "@mui/material/DialogTitle";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { BoxCatMintView } from "./mintView";
import { DoneView } from "../../common/doneView";
import { ErrorView } from "../../common/errorView";
import { isMobile } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { closeBoxCatMintForm } from "../ModalFormSlice";
import { PowerBy } from "../../common/PowerBy";

const titleClass = { fontStyle: "italic", fontWeight: "700", fontSize: "24px", alignSelf: "flex-start" }

export const BoxcatMintModal = () => {
    const isOpen = useSelector((state) => state.modalForm.boxcatMintFormOpen);
    const info = useSelector((state) => state.modalForm.info);
    const dispath = useDispatch();

    const [step, setStep] = useState(1)
    const [txHash, setTxHash] = useState(undefined);
    const handleClose = () => {
        dispath(closeBoxCatMintForm());
        setStep(1);
    }

    const cardClasses = isMobile() ? { minWidth: 275 } : { minWidth: 475 };

    return (
        <>
            <Dialog
                PaperProps={{
                    style: { borderRadius: 20, fontFamily: `"Roboto","Helvetica","Arial",sans-serif` }
                }}
                onClose={handleClose}
                open={isOpen}
            >
                <DialogTitle style={titleClass}>Mint</DialogTitle>
                <Card sx={cardClasses}>
                    <CardContent>
                        <div>
                            {(step === 1) &&
                                <BoxCatMintView
                                    setStep={setStep}
                                    info={info}
                                    setTxHash={setTxHash}
                                />}
                            {(step === 4) && <DoneView txHash={txHash} message={"Congratuations! You successfully bought!"} />}
                            {(step === 5) && <ErrorView message={"Sorry, you are not able to mint Boxcatplanet Medal."} discord={"https://t.co/vLz3ZHfBDv"} />}
                        </div>
                    </CardContent>
                    <PowerBy />
                </Card>
            </Dialog>
        </>


    )
}