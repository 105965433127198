import React, { useState, useEffect } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { useStyles } from "../../muiStyle";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

import "../../mint.css";
import { setProxyBurn } from "../../../contract_access_object/writerCao";
import { decodeLogsBy, getTransactionReceiptMined } from "../../../componentHelper";
import { MOCK } from "../../../contract_access_object/constants";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { setPostOrderBody } from "../../common/commonSlice";

export const BurnView = ({ setStep, setTxHash }) => {
    const dispatch = useDispatch();
    const info = useSelector((state) => state.modalForm.info);
    const walletAddress = useSelector((state) => state.userModal.walletAddress);
    const chain = useSelector((state) => state.userModal.chainId);
    const [quantity, setQuantity] = useState(1);
    const [txnStatus, setTxnStatus] = useState("");
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const handleChange = (event) => {
        setTxnStatus("");
        setQuantity(event.target.value);
    };

    const handleRedeem = async () => {
        setTxnStatus("Waiting for approval");
        try {

            const txn = MOCK ? "TEST" : await setProxyBurn(info.contractAddr, info.tokenId, quantity);
            if (txn) {
                setTxnStatus("Loading");
                const txnRes = await getTransactionReceiptMined(txn.hash);
                const afterInfo = await decodeLogsBy(txnRes.logs, info.logicAddr, "Transfer");
                var mintedTokenId = [];
                for (let i = 1; i < afterInfo.length; i++) {
                    if (afterInfo[i]) {
                        mintedTokenId.push(Number(afterInfo[i].tokenId));
                    }
                }

                const redeemed_tokens = [{
                    receipt_address: "",
                    receipt_token_ids: mintedTokenId,
                    redeemed_number: quantity,
                    token_id: Number(info.tokenId)
                }]

                setTxHash(txn.hash)

                dispatch(setPostOrderBody({
                    transaction_hash: txn.hash,
                    redeemed_tokens,
                    status: "submitted",
                    wallet_address: walletAddress,
                    chain_id: chain.id,
                    proxy_address: info.contractAddr,
                }))
                setStep(2);
            }
            else {
                setTxnStatus("");
            }
        }
        catch (e) {
            console.log("error", e);
            setTxnStatus("");
            const msg = e.message;
            const regex = /reason="(.*?)",/;
            const found = msg.match(regex);
            if (found) {
                enqueueSnackbar(found[1], {
                    variant: "error",
                });
            }
            else {
                enqueueSnackbar(msg.split("(")[0], {
                    variant: "error",
                });
            }
        }
    }

    useEffect(() => {
        if (txnStatus === "DONE") {
            setStep(2);
        }
    },);

    useEffect(() => {
        if (Number(info.owned) === 0) {
            setStep(5);
        }
    }, [info.owned]);

    return (
        <>
            <div className="cardWrapper">
                <div className="select-wrapper">
                    <div className="product-tag">
                        <h1>{info.name}</h1>
                        <h2>You have {info.owned} items available</h2>
                    </div>
                    <FormControl sx={{ m: 1, minWidth: 80 }} className={classes.root} size="small">
                        <Select
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            value={quantity}
                            onChange={handleChange}
                        >
                            {Array.from({ length: info.owned }, (_, i) => i + 1).map((element, index) => (
                                <MenuItem value={element} key={index}>{element}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <button disabled={txnStatus !== ""} className="common-button" variant="outlined" onClick={handleRedeem}>
                    {txnStatus ? txnStatus : "Redeem"}
                    {txnStatus === "Loading" && <CircularProgress color="inherit" size="1rem" />}
                </button>
            </div>
        </>

    )
}