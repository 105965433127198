import React from "react";
import CardContent from '@mui/material/CardContent';
import { FaDiscord } from "react-icons/fa";
import "./errorView.css";

export const ErrorView = ({ message, discord }) => {
    return (
        <div>
            <CardContent>
                <div className="cardWrapper">
                    <h1>
                        {message}
                    </h1>
                    {discord &&
                        <a href={discord} target="_blank" rel="noreferrer" className="discord">
                            <h1>Join our Discord if you have any question</h1>
                            <FaDiscord />
                        </a>
                    }
                </div>
            </CardContent>
        </div>

    )
}