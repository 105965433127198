import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mintFormOpen: false,
    boxcatMintFormOpen: false,
    ecommerceMintFormOpen: false,
    ecommerceBurnFormOpen: false,
    info: {},
};

const modalFormSlice = createSlice({
    name: "modalForm",
    initialState,
    reducers: {
        updateInfo: (state, action) => {
            state.info = action.payload
        },
        openMintForm: (state) => {
            state.mintFormOpen = true;
        },
        closeMintForm: (state) => {
            state.mintFormOpen = false;
        },
        openBoxCatMintForm: (state) => {
            state.boxcatMintFormOpen = true;
        },
        closeBoxCatMintForm: (state) => {
            state.boxcatMintFormOpen = false;
        },
        openEcommerceMintForm: (state) => {
            state.ecommerceMintFormOpen = true;
        },
        closeEcommerceMintForm: (state) => {
            state.ecommerceMintFormOpen = false;
        },
        openEcommerceBurnForm: (state) => {
            state.ecommerceBurnFormOpen = true;
        },
        closeEcommerceBurnForm: (state) => {
            state.ecommerceBurnFormOpen = false;
        },
    },
});

export const {
    updateInfo,
    openMintForm,
    closeMintForm,
    openBoxCatMintForm,
    closeBoxCatMintForm,
    openEcommerceMintForm,
    closeEcommerceMintForm,
    openEcommerceBurnForm,
    closeEcommerceBurnForm,
} = modalFormSlice.actions;

export default modalFormSlice.reducer;
