import React, { useEffect } from 'react';
import { openOrdersModal } from '../OrdersModal/OrdersModalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useSignMessage } from 'wagmi'
import { useSnackbar } from 'notistack';
import { updateOrderSignData } from './UserModalSlice';
import { utils } from 'ethers';

import "./UserModal.css";
import { timeRound } from '../../constants';

export const CheckOrderHistory = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const provider = useSelector(state => state.userModal.provider);
    const walletAddress = useSelector(state => state.userModal.walletAddress);
    const orderSignData = useSelector(state => state.userModal.orderSignData);
    const signTime = useSelector(state => state.userModal.signTime);
    const [message, setMessage] = React.useState('')
    const [clickSign, setClickSign] = React.useState(false)
    const { data, isError, isLoading, isSuccess, signMessage } = useSignMessage({
        message,
    })

    useEffect(() => {
        if (isSuccess) {
            setClickSign(false);
            setMessage('');
            dispatch(openOrdersModal());
            dispatch(updateOrderSignData(data));
            console.log(data);
        }
    }, [isSuccess, data, dispatch]);

    useEffect(() => {
        if (isError) {
            setClickSign(false);
            setMessage('');
            enqueueSnackbar('Error signing message', {
                variant: 'error',
            });
        }
    }, [isError, enqueueSnackbar]);

    useEffect(() => {
        if (clickSign && message) {
            console.log('signing message ', message);
            signMessage();
        }
    }, [clickSign, message, signMessage]);


    const handleOrderHistory = async () => {
        const curTime = Math.round(Date.now() / timeRound);
        if (orderSignData && (signTime && (curTime - signTime) < 3)) {
            dispatch(openOrdersModal());
        }
        else {
            const curTime = Math.round(Date.now() / timeRound);
            // setCurTime(time);
            const nonce = await provider.getTransactionCount(walletAddress);

            const hash = utils.keccak256(utils.toUtf8Bytes(walletAddress + nonce + curTime));
            console.log(walletAddress, nonce, curTime, hash);
            setMessage(`Check Order History in CatchOn ${hash}`);
            setClickSign(true);
            console.log('signing message ', message);
        }
    };
    return (
        <button disabled={isLoading} onClick={handleOrderHistory} className="common-button">
            My Orders
        </button>
    );
};