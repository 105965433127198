import { createSlice } from "@reduxjs/toolkit";
import { updateConnectButtonText } from "../../divCheck/connectButtonCheck";
import { isValidAddress } from "../../wallet/utils";
import { formatWalletAddress } from "../../utils";
import { timeRound } from "../../constants";

const initialState = {
    userModalOpen: false,
    walletAddress: "",
    chainId: {},
    provider: {},
    signer: {},
    orderSignData: "",
    signTime: 0,
};

const userModalReducer = createSlice({
    name: "userModal",
    initialState,
    reducers: {
        openUserModal: (state) => {
            state.userModalOpen = true
        },
        closeUserModal: (state) => {
            state.userModalOpen = false;
        },
        updateWalletAddress: (state, action) => {
            state.walletAddress = action.payload
            if (isValidAddress(state.walletAddress)) {
                updateConnectButtonText(formatWalletAddress(state.walletAddress));
            }
            else {
                updateConnectButtonText("Connect Wallet");
            }
            state.orderSignData = "";
        },
        updateChainId: (state, action) => {
            state.chainId = action.payload
        },
        updateProvider: (state, action) => {
            state.provider = action.payload
        },
        updateSigner: (state, action) => {
            state.signer = action.payload
        },
        updateOrderSignData: (state, action) => {
            state.orderSignData = action.payload
            state.signTime = Math.round(Date.now() / timeRound)
        },
    },
});

export const {
    openUserModal,
    closeUserModal,
    updateWalletAddress,
    updateChainId,
    updateProvider,
    updateSigner,
    updateOrderSignData,
} = userModalReducer.actions;

export default userModalReducer.reducer;
