import { ENABLE_MOCK } from "../constants";
import {
  fetchAbiOfContractAt,
} from "./abiHelper";

import {
  assert,
} from "./web3Helper";

import { ethers } from "ethers";

let initializedContracts = {}; // initialized Contract objects

export const fetchContractObjByAddr = (addr) => {
  assert(
    initializedContracts[addr],
    `No contract object initialized for address ${addr}!`
  );
  return initializedContracts[addr];
};

export const initProxyContract = async (
  proxyAddr,
  logicAddr,
  signer,
) => {
  console.log("Initializing proxy contract...", proxyAddr);
  await initContract(proxyAddr, logicAddr, signer);
};

export const connectWalletInit = async (signer) => {
  console.log("Connecting to wallet...");
  if (window.CONTRACT_ADDRESS && window.LOGIC_ADDRESS) {
    initProxyContract(window.CONTRACT_ADDRESS, window.LOGIC_ADDRESS, signer);
  }
};

export const getDefaultContract = () => {
  return window.CONTRACT_ADDRESS;
};

// export const verifyAccount = async () => {
//   const accounts = await web3.eth.getAccounts();
//   const account = accounts[0];
//   const message = LOGIN_VERIFICATION_PHRASE;
//   const signature = await web3.eth.personal.sign(message, account);
//   const msg = `0x${Buffer.from(message, "utf8").toString("hex")}`;
//   const recoveredAddr = await eth_util.recoverPersonalSignature({
//     data: msg,
//     sig: signature,
//   });
//   console.log(recoveredAddr);
//   return recoveredAddr.toLowerCase() === account.toLowerCase();
// };

const initContract = async (
  contractAddr,
  abiAddr,
  signer,
) => {
  if (ENABLE_MOCK) {
    return;
  }
  if (!signer) {
    throw new Error("signer is undefined");
  }
  // TODO check if network swtiched
  if (contractAddr in initializedContracts) {
    return;
  }
  const contract = await initContractGlobalObject(contractAddr, abiAddr);
  initializedContracts[contractAddr] = await initEthers(contract, signer);
  // console.log(`Initialized contract ${contractAddr}`);
};

const initEthers = async (contract, signer) => {
  const address = contract.address[contract.allowedNetworks[0]];
  const abi = contract.abi;
  return new ethers.Contract(address, abi, signer);
};

const initContractGlobalObject = async (addr, abiAddr) => {
  const chainID = getChainIdFromWindow();
  return {
    address: {
      [chainID]: addr,
    },
    abi: await fetchAbiOfContractAt(abiAddr, chainID),
    allowedNetworks: [chainID],
  };
};

export const getChainIdFromWindow = () => {
  return window.IS_TEST ? 5 : window.CHAINID ? window.CHAINID : 5;
}
