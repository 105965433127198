import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    ordersModalOpen: false,
    orderRawCache: {},
    ordersFromDB: [],
};

const ordersModalSlice = createSlice({
    name: "ordersModal",
    initialState,
    reducers: {
        openOrdersModal: (state) => {
            state.ordersModalOpen = true
        },
        closeOrdersModal: (state) => {
            state.ordersModalOpen = false;
        },
        setOrderRawCache: (state, action) => {
            state.orderRawCache = action.payload;
        },
        updateOrdersFromDB: (state, action) => {
            state.ordersFromDB = action.payload;
        },
    },
});

export const {
    openOrdersModal,
    closeOrdersModal,
    setOrderRawCache,
    updateOrdersFromDB,
} = ordersModalSlice.actions;

export default ordersModalSlice.reducer;
