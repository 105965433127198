import React, { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from "@mui/material/DialogTitle";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { AiOutlineCloseCircle } from "react-icons/ai";

import { MintView } from "./mintView";
import { DoneView } from "../../common/doneView";
import { ErrorView } from "../../common/errorView";
import { BeforeMintView } from "../../common/BeforeMintView";
import { isMobile } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { closeMintForm } from "../ModalFormSlice";
import { PowerBy } from "../../common/PowerBy";

import "../commonModal.css";
import { AddShippingView } from "../../common/AddShippingView";
import { PostOrder } from "../../common/PostOrder";
import { updateOrderPostSuccess } from "../../common/commonSlice";

const titleClass = { width: "100%", padding: "0px" }

export const BaseMintModal = () => {
    const isOpen = useSelector((state) => state.modalForm.mintFormOpen);
    const info = useSelector((state) => state.modalForm.info);
    const orderPostSuccess = useSelector((state) => state.common.orderPostSuccess);
    const dispath = useDispatch();

    const [step, setStep] = useState(1)
    const [txHash, setTxHash] = useState(undefined);
    const handleClose = () => {
        dispath(closeMintForm());
        setStep(1);
    }

    const handleSuccess = () => {
        setStep(4);
    }

    const soldOut = (parseInt(info.maxAvailable) === 0) && (parseInt(info.startTime) > 0) ? true : false;

    const cardClasses = isMobile() ? { minWidth: 275 } : { minWidth: 475 };

    useEffect(() => {
        if (orderPostSuccess) {
            handleSuccess();
            dispath(updateOrderPostSuccess(false))
        }
    }, [orderPostSuccess])

    return (
        <>
            <Dialog
                PaperProps={{
                    style: { borderRadius: 20, fontFamily: `"Roboto","Helvetica","Arial",sans-serif` }
                }}
                open={isOpen}
            >
                <DialogTitle style={titleClass}>
                    <div className="common-title">
                        <h1>
                            Mint
                        </h1>
                        <button onClick={handleClose} style={{ border: "none", background: "none", cursor: "pointer", width: "auto", height: "auto" }}>
                            <AiOutlineCloseCircle size={25} />
                        </button>
                    </div>
                </DialogTitle>
                <Card sx={cardClasses}>
                    <CardContent>
                        {soldOut ?
                            <ErrorView message={"Sorry, we are sold out."} />
                            :
                            <div>
                                {(step === 1) && <BeforeMintView setStep={setStep} info={info} />}
                                {(step === 2) &&
                                    <MintView
                                        setStep={setStep}
                                        setTxHash={setTxHash}
                                    />}
                                {(step === 3) && <AddShippingView />}
                                {(step === 4) && <DoneView txHash={txHash} message={"Congratuations! You successfully bought!"} />}
                                {(step === 5) && <ErrorView message={"Sorry, you are not able to mint."} />}
                            </div>

                        }

                    </CardContent>
                    <PowerBy />
                </Card>
            </Dialog>
        </>


    )
}