export const MOCK_API = false;

export const GAS_INCREASE = 1.16;

export const MINTED_CHECK_CAP = 5000;

export const timeRound = 100000;

export const API_DB = "https://11xykht95a.execute-api.us-west-1.amazonaws.com/";
export const API_SQL_DB = MOCK_API ? "http://localhost:3000/ecommerce/" : "https://shield-swart.vercel.app/ecommerce/";
export const IPFS_GATEWAY = "https://anzh.mypinata.cloud/ipfs/";
export const IMAGE_PLACEHOLDER = "https://via.placeholder.com/150";

export const ENABLE_MOCK = false;

export const APP_TYPE_ECOMMERCE = "ecommerce";
export const APP_TYPE_BOXCAT = "boxcat";
export const APP_TYPE_BASE_NFT = "blanknft";
export const APP_TYPE_MEMBERSHIP = "membership";

export const APP_TYPES = [APP_TYPE_ECOMMERCE, APP_TYPE_BOXCAT, APP_TYPE_BASE_NFT, APP_TYPE_MEMBERSHIP];