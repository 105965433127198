// This file is for collecting shipping information from the user
// After clicking submit, click the sign button to sign the message
// This module will prepare the sign message and send it to the SignLoading module

import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import { useStyles } from "../muiStyle";
import { utils } from "ethers";
import { useDispatch, useSelector } from "react-redux";

import "./AddShippingView.css";
import { timeRound } from "../../constants";
import { setMessage, setRecipient, setSignClick, updateOrderUpdateLoading } from "./commonSlice";
import { SignLoading } from "./SignLoading";
import { CircularProgress } from "@mui/material";

export const AddShippingView = ({ handleCancel }) => {
    const dispatch = useDispatch();
    const walletAddress = useSelector((state) => state.userModal.walletAddress);
    const provider = useSelector((state) => state.userModal.provider);
    const orderUpdateLoading = useSelector((state) => state.common.orderUpdateLoading);
    const [address, setAddress] = useState("");
    const [apt, setApt] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [zip, setZip] = useState("");
    const [country, setCountry] = useState("");
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [phoneNum, setPhoneNum] = useState("")
    const [email, setEmail] = useState("")

    const [isFormInvalidFirstName, setIsFormInvalidFirstName] = useState(false);
    const [isFormInvalidLastName, setIsFormInvalidLastName] = useState(false);
    const [isFormInvalidPhone, setIsFormInvalidPhone] = useState(false);
    const [isFormInvalidAddress, setIsFormInvalidAddress] = useState(false);
    const [isFormInvalidCity, setIsFormInvalidCity] = useState(false);
    const [isFormInvalidState, setIsFormInvalidState] = useState(false);
    const [isFormInvalidZip, setIsFormInvalidZip] = useState(false);
    const [isFormInvalidCountry, setIsFormInvalidCountry] = useState(false);

    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    }
    const handleAptChange = (event) => {
        setApt(event.target.value);
    }
    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value);
    }
    const handleLastNameChange = (event) => {
        setLastName(event.target.value);
    }
    const handlePhoneNumChange = (event) => {
        setPhoneNum(event.target.value);
    }
    const handleCityChange = (event) => {
        setCity(event.target.value);
    }
    const handleStateChange = (event) => {
        setState(event.target.value);
    }
    const handleZipChange = (event) => {
        setZip(event.target.value);
    }
    const handleCountryChange = (event) => {
        setCountry(event.target.value);
    }
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    const classes = useStyles();

    const handleAddressSubmit = async () => {
        if (!firstName) {
            setIsFormInvalidFirstName(true);
            return;
        }
        setIsFormInvalidFirstName(false);
        if (!lastName) {
            setIsFormInvalidLastName(true);
            return;
        }
        setIsFormInvalidLastName(false);

        if (!address) {
            setIsFormInvalidAddress(true);
            return;
        }
        setIsFormInvalidAddress(false);

        if (!city) {
            setIsFormInvalidCity(true);
            return;
        }
        setIsFormInvalidCity(false);

        if (!state) {
            setIsFormInvalidState(true);
            return;
        }
        setIsFormInvalidState(false);

        if (!zip) {
            setIsFormInvalidZip(true);
            return;
        }
        setIsFormInvalidZip(false);

        if (!country) {
            setIsFormInvalidCountry(true);
            return;
        }
        setIsFormInvalidCountry(false);

        if (!phoneNum) {
            setIsFormInvalidPhone(true);
            return;
        }
        setIsFormInvalidPhone(false);

        const recipient =
        {
            first_name: firstName,
            last_name: lastName,
            street: address,
            apt,
            city,
            state,
            zipcode: zip,
            country,
            phone: phoneNum,
            email
        }

        dispatch(setRecipient(recipient));

        const curTime = Math.round(Date.now() / timeRound);
        const nonce = await provider.getTransactionCount(walletAddress);
        const hash = utils.keccak256(utils.toUtf8Bytes(walletAddress + nonce + curTime));
        console.log(walletAddress, nonce, curTime, hash);
        dispatch(setMessage(`Check Order History in CatchOn ${hash}`));
        dispatch(setSignClick(true));
        dispatch(updateOrderUpdateLoading(true));
    }

    const handleCancelInner = () => {
        handleCancel();
    }

    return (
        <>
            <SignLoading />
            <div className="cardWrapper ship">
                <div className="card-row">
                    <div className="item-input">
                        <h1>First Name</h1>
                        <TextField fullWidth id="outlined-basic" variant="outlined" className={classes.root} value={firstName} size="small"
                            onChange={handleFirstNameChange}
                            required
                            helperText={
                                isFormInvalidFirstName && firstName === ""
                                    ? "Please fill in the first name"
                                    : " "
                            }
                            error={isFormInvalidFirstName}
                        />
                    </div>
                    <div className="item-input">
                        <h1>Last Name</h1>
                        <TextField fullWidth id="outlined-basic" variant="outlined" className={classes.root} value={lastName} size="small"
                            onChange={handleLastNameChange}
                            required
                            helperText={
                                isFormInvalidLastName && lastName === ""
                                    ? "Please fill in the last name"
                                    : " "
                            }
                            error={isFormInvalidLastName}
                        />
                    </div>
                </div>
                <div className="item-input">
                    <h1>Shipping address #1</h1>
                    <TextField fullWidth id="outlined-basic" variant="outlined" className={classes.root} value={address} size="small"
                        onChange={handleAddressChange}
                        required
                        helperText={
                            isFormInvalidAddress && address === ""
                                ? "Please fill in the shipping address"
                                : " "
                        }
                        error={isFormInvalidAddress}
                    />
                </div>
                <div className="item-input">
                    <h1>Shipping address #2</h1>
                    <TextField fullWidth id="outlined-basic" variant="outlined" className={classes.root} value={apt} size="small"
                        onChange={handleAptChange}
                    />
                </div>
                <div className="card-row">
                    <div className="item-input">
                        <h1>City</h1>
                        <TextField fullWidth id="outlined-basic" variant="outlined" className={classes.root} value={city} size="small"
                            onChange={handleCityChange}
                            required
                            helperText={
                                isFormInvalidCity && city === ""
                                    ? "Please fill in the city"
                                    : " "
                            }
                            error={isFormInvalidCity}
                        />
                    </div>
                    <div className="item-input">
                        <h1>State/Province</h1>
                        <TextField fullWidth id="outlined-basic" variant="outlined" className={classes.root} value={state} size="small"
                            onChange={handleStateChange}
                            required
                            helperText={
                                isFormInvalidState && state === ""
                                    ? "Please fill in the state"
                                    : " "
                            }
                            error={isFormInvalidState}
                        />
                    </div>
                </div>
                <div className="card-row">
                    <div className="item-input">
                        <h1>Zip/Postal Code</h1>
                        <TextField fullWidth id="outlined-basic" variant="outlined" className={classes.root} value={zip} size="small"
                            onChange={handleZipChange}
                            required
                            helperText={
                                isFormInvalidZip && zip === ""
                                    ? "Please fill in the zip code"
                                    : " "
                            }
                            error={isFormInvalidZip}
                        />
                    </div>
                    <div className="item-input">
                        <h1>Country</h1>
                        <TextField fullWidth id="outlined-basic" variant="outlined" className={classes.root} value={country} size="small"
                            onChange={handleCountryChange}
                            required
                            helperText={
                                isFormInvalidCountry && country === ""
                                    ? "Please fill in the country"
                                    : " "
                            }
                            error={isFormInvalidCountry}
                        />
                    </div>
                </div>
                <div className="item-input">
                    <h1>Phone Number</h1>
                    <TextField fullWidth id="outlined-basic" variant="outlined" className={classes.root} value={phoneNum} size="small"
                        onChange={handlePhoneNumChange}
                        required
                        helperText={
                            isFormInvalidPhone && phoneNum === ""
                                ? "Please fill in the phone number"
                                : " "
                        }
                        error={isFormInvalidPhone}
                    />
                </div>
                <div className="row-button">
                    <button className="common-button cancel-button" onClick={handleCancelInner}>
                        Cancel
                    </button>
                    <button className="common-button " onClick={handleAddressSubmit}>
                        {orderUpdateLoading ? "Loading" : "Submit"}
                        {orderUpdateLoading && <CircularProgress color="inherit" size="1rem" />}
                    </button>
                </div>
            </div>
        </>

    )
}