import React, { useState, useEffect } from "react";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { convertWeiToETH } from '../../../utils'
import { MOCK } from "../../../contract_access_object/constants";
import { useStyles } from "../../muiStyle";

import "../../mint.css";
import { getTransactionReceiptMined } from "../../../componentHelper";
import { getMintButtonText } from "../../../dataLoader/dataLoader";
import { setBoxcatMint } from "../../../contract_access_object/boxcat/writerCao";
import { useSnackbar } from "notistack";

export const BoxCatMintView = ({ setStep, info, setTxHash }) => {
    const [quantity, setQuantity] = useState(1);
    const [nftIndex, setNftIndex] = useState(0);
    const [txnStatus, setTxnStatus] = useState("");
    const finalPrice = ((info.price ? info.price : 0) * quantity).toString();
    const purchaseLimit = info.available ? (info.available[nftIndex] ? info.available[nftIndex] : 0) : 0;
    const selections = purchaseLimit ? Array.from({ length: purchaseLimit }, (_, i) => i + 1) : [1];
    const showTap = info.available ? info.available : [1, 1, 1];
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (info.available) {
            if (info.available.length > 0) {
                if (info.available.reduce((a, b) => a + b, 0) === 0) {
                    console.log("available", info.available)
                    setStep(5);
                }
                for (let i of info.available) {
                    if (i > 0) {
                        setNftIndex(info.available.indexOf(i));
                        break;
                    }
                }
            }
        }
    }, [info.available]);

    const handleChange = (event) => {
        setTxnStatus("");
        setQuantity(event.target.value);
    };

    const handleNftIndexChange = (event, newIndex) => {
        setNftIndex(newIndex);
    };

    const handleMint = async () => {
        setTxnStatus("Wait for approval");
        try {
            const txn = MOCK ? "test" : await setBoxcatMint(
                info.contractAddr,
                quantity,
                nftIndex,
                finalPrice
            );
            if (txn) {
                setTxnStatus("Loading");
                console.log("CREATE txn", txn)
                const txnRes = await getTransactionReceiptMined(txn.hash);
                console.log("txnRes", txnRes)
                setTxHash(txn.hash);
                setStep(4);
            }
            else {
                setTxnStatus("");
            }
        } catch (e) {
            console.log("error", e);
            setTxnStatus("");
            const msg = e.message;
            const regex = /reason="(.*?)",/;
            const found = msg.match(regex);
            if (found) {
                enqueueSnackbar(found[1], {
                    variant: "error",
                });
            }
            else {
                enqueueSnackbar(msg.split("(")[0], {
                    variant: "error",
                });
            }
        }
    }
    return (
        <div className="cardWrapper">
            <ToggleButtonGroup
                value={nftIndex}
                exclusive
                onChange={handleNftIndexChange}
            >
                {showTap[0] && <ToggleButton value={0}
                    sx={{
                        "&.MuiToggleButtonGroup-grouped": {
                            borderRadius: "10px !important",
                            border: "1px solid lightgrey !important"
                        }
                    }}
                >Golden</ToggleButton>}
                {showTap[1] && <ToggleButton value={1}
                    sx={{
                        "&.MuiToggleButtonGroup-grouped": {
                            borderRadius: "10px !important",
                            border: "1px solid lightgrey !important"
                        }
                    }}
                >Black</ToggleButton>}
                {showTap[2] && <ToggleButton value={2}
                    sx={{
                        "&.MuiToggleButtonGroup-grouped": {
                            borderRadius: "10px !important",
                            border: "1px solid lightgrey !important"
                        }
                    }}
                >Sliver</ToggleButton>}
            </ToggleButtonGroup>
            <div className="select-wrapper">
                <div className="product-tag">
                    <h1>{info.name}</h1>
                    <h2>{Number(info.minted)} items minted</h2>
                </div>
                <FormControl sx={{ m: 1, minWidth: 80 }} className={classes.root} size="small">
                    <Select
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        value={quantity}
                        onChange={handleChange}
                    >
                        {selections.map((element, index) => (
                            <MenuItem value={element} key={index}>{element}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className="price-tag">
                <div className="small">Subtotal:</div>
                <div className="bold">{convertWeiToETH((finalPrice).toString())} ETH</div>
            </div>
            <button disabled={txnStatus !== ""} className="common-button" variant="outlined" onClick={handleMint}>
                {txnStatus ? txnStatus : getMintButtonText(info.type)}
                {txnStatus === "Loading" && <CircularProgress color="inherit" size="1rem" />}
            </button>
        </div>
    )
}