// This is a sign loading component that is used to sign a message
// Once it is signed, it will dispatch the signature to the store

import { useSignMessage } from "wagmi";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSignClick, setSignature } from "./commonSlice";

export const SignLoading = () => {
    const dispatch = useDispatch();
    const message = useSelector(state => state.common.message);
    const signClick = useSelector(state => state.common.signClick);
    const { data, isError, isSuccess, signMessage } = useSignMessage({
        message,
    })

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (isSuccess) {
            dispatch(setSignClick(false));
            dispatch(setSignature(data));
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isError) {
            dispatch(setSignClick(false));
            enqueueSnackbar('Error signing message', {
                variant: 'error',
            });
        }
    }, [isError, enqueueSnackbar]);

    useEffect(() => {
        if (signClick && message) {
            console.log('signing message ', message);
            signMessage();
        }
    }, [signClick, message, signMessage]);
    return (
        <div className="sign-loading">

        </div>
    );
}