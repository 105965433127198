import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { closeOrdersModal, updateOrdersFromDB } from "./OrdersModalSlice";

import "./OrdersModal.css";
import { OrderCard } from "./OrderCard";
import { AddShippingView } from "../common/AddShippingView";
import { isMobile } from "web3modal";
import { titleClass } from "../muiStyle";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { PowerBy } from "../common/PowerBy";
import { formatOrders, getOrders } from "../../dataLoader/dataLoader";
import { getDefaultContract } from "../../wallet/wallet";
import { useSnackbar } from "notistack";
import { PutOrder } from "../common/PutOrder";

export const OrdersModal = () => {
    const dispatch = useDispatch();
    const ordersModalOpen = useSelector(state => state.ordersModal.ordersModalOpen);
    const ordersFromDB = useSelector(state => state.ordersModal.ordersFromDB);
    const orderSignData = useSelector(state => state.userModal.orderSignData);
    const walletAddress = useSelector(state => state.userModal.walletAddress);
    const chain = useSelector(state => state.userModal.chainId);
    const [step, setStep] = React.useState(0);
    const [orders, setOrders] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleClose = () => {
        dispatch(closeOrdersModal());
    };

    const handleCancel = () => {
        setStep(0);
    }

    const handleSuccess = () => {
        setStep(0);
    }

    const forMartRawOrder = (order) => {
        console.log("order", order);
        const redeemedTokens = _.get(order, "redeemed_tokens", []);
        const tokenId = _.get(redeemedTokens[0], "token_id", "");
        const redeemed = _.get(redeemedTokens[0], "redeemed_number", 0);
        const mintedTokenId = _.get(redeemedTokens[0], "receipt_tokenIds", []);
        const receiptAddress = _.get(redeemedTokens[0], "receipt_address", "");
        const contractAddr = _.get(order, "proxy_address", "");
        const status = _.get(order, "status", "");
        const txnHash = _.get(order, "transaction_hash", "");
        console.log("tokenId", tokenId, "redeemed", redeemed, "mintedTokenId", mintedTokenId, "receiptAddress", receiptAddress, "contractAddr", contractAddr, "status", status, "txnHash", txnHash);
        return {
            tokenId,
            redeemed,
            mintedTokenId,
            receiptAddress,
            contractAddr,
            status,
            txnHash
        }
    }

    const handleAddShip = () => {
        setStep(1);
    }

    const fetchOrderData = async (forceFetchData) => {
        setLoading(true);
        const contractAddress = getDefaultContract();
        const ordersFromDBTmp = await getOrders(walletAddress, orderSignData, contractAddress, chain.id, forceFetchData);
        // const dataImage = await getMetaData(getDefaultWallet(), 0, "1155");
        if (ordersFromDBTmp) {
            if (ordersFromDBTmp.length > 0) {
                console.log("ordeordersFromDBrs", ordersFromDBTmp);
                dispatch(updateOrdersFromDB(ordersFromDBTmp));
            }
            else {
                setLoading(false);
            }
        }
        else {
            enqueueSnackbar(`Error when fetching data from database`, { variant: "error" });
        }
    }

    const fetchFormatOrders = async () => {
        const contractAddress = getDefaultContract();
        const finalOrders = await formatOrders(contractAddress, ordersFromDB);
        if (finalOrders) {
            console.log("finalOrders", finalOrders);
            setOrders(finalOrders);
            setLoading(false);
        }
        else {
            enqueueSnackbar(`Error when formating data from database`, { variant: "error" });
        }
    }

    useEffect(() => {
        if (ordersFromDB && ordersFromDB.length > 0) {
            fetchFormatOrders();
        }
    }, [ordersFromDB])

    useEffect(() => {
        if (ordersModalOpen && orderSignData) {
            console.log("orderSignData", orderSignData);
            try {
                fetchOrderData(true);
            } catch (error) {
                console.log("error", error);
                enqueueSnackbar(`Error when fetching data: ${error.message}`, { variant: "error" });
            }
        }
    }, [ordersModalOpen, orderSignData])

    const cardClasses = isMobile() ? { minWidth: 275 } : { minWidth: 540 };

    return (
        <>
            <PutOrder handleSuccess={handleSuccess} />
            <Dialog
                open={ordersModalOpen}
                PaperProps={{
                    style: { borderRadius: 10, fontFamily: `"Roboto","Helvetica","Arial",sans-serif`, maxWidth: 800 }
                }}
            >
                <DialogTitle style={titleClass}>
                    <div className="common-title">
                        <h1>
                            My Orders
                        </h1>
                        <button onClick={handleClose} style={{ border: "none", background: "none", cursor: "pointer", width: "auto", height: "auto" }}>
                            <AiOutlineCloseCircle size={25} />
                        </button>
                    </div>
                </DialogTitle>
                <DialogContent sx={cardClasses}>
                    {(step === 0) &&
                        <div className="order-modal-wrapper" >
                            <div className="order-modal-element">
                                {!loading &&
                                    <>
                                        {orders.length === 0 ?
                                            <p>No orders found</p>
                                            :
                                            orders.map((order, index) =>
                                                <OrderCard order={order} handleAddShip={handleAddShip} key={index} />
                                            )
                                        }
                                    </>
                                }
                                {loading &&
                                    <p>Loading data ...</p>
                                }
                            </div>
                        </div>
                    }
                    {(step === 1) &&
                        <AddShippingView handleCancel={handleCancel} handleSuccess={handleSuccess} />
                    }
                </DialogContent>
                <PowerBy />
            </Dialog>
        </>
    )
}