// import { updateConnectButton, updateWalletStatus } from "./wallet.js";
import { updateMintButton } from "./mintButtonCheck";
import { updateBurnButton } from "./burnButtonCheck";
import { updateConnectButton } from "./connectButtonCheck";
// import { blacklist } from "./blacklist";

export const initButtonCheck = async () => {

    updateConnectButton();
    updateMintButton();
    updateBurnButton();
}

// init();
