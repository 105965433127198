import { BaseMintModal } from "./components/ModalForm/BaseMintModal/BaseMintModal";
import { BoxcatMintModal } from "./components/ModalForm/BoxCatModal/BoxCatModal";
import { EcommerceMintModal } from "./components/ModalForm/EcommerceBurnModal/EcommerceBurnModal";
import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { mainnet, polygon, optimism, arbitrum, goerli } from 'wagmi/chains';
import { infuraProvider } from 'wagmi/providers/infura';
import { publicProvider } from 'wagmi/providers/public';
import { UserModal } from './components/UserModal/UserModal';
import { OrdersModal } from './components/OrdersModal/OrdersModal';
import { PostOrder } from "./components/common/PostOrder";
const { chains, provider } = configureChains(
  [mainnet, polygon, optimism, arbitrum, goerli],
  [
    infuraProvider({ apiKey: "d85b23f501584a4aa0a6cfdf6ad3d8bc" }),
    publicProvider(),
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'My RainbowKit App',
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
})
function App() {

  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider chains={chains}>
          <UserModal />
          <OrdersModal />
          <BaseMintModal />
          <BoxcatMintModal />
          <EcommerceMintModal />
          <PostOrder />
        </RainbowKitProvider>
      </WagmiConfig>
    </>
  );
}

export default App;
