// This is a put order component that is used to put an order
// The entry point is readOrderInfo, which is the order info that is read when user click edit address button
// When signature and recipient are ready, it will put new order to the database
// Once it is done, it will read the order from the database and update the order list

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formatReadOrder, getOrders, putOrder } from "../../dataLoader/dataLoader";
import { sleep } from "../../utils";
import { useSnackbar } from "notistack";
import { setRecipient, setSignature, updateOrderUpdateLoading, updateReadOrderInfo } from "./commonSlice";
import { getDefaultContract } from "../../wallet/wallet";
import { updateOrdersFromDB } from "../OrdersModal/OrdersModalSlice";


export const PutOrder = ({ handleSuccess }) => {
    const recipient = useSelector((state) => state.common.recipient);
    const readOrderInfo = useSelector((state) => state.common.readOrderInfo);
    const signature = useSelector((state) => state.common.signature);
    const walletAddress = useSelector((state) => state.userModal.walletAddress);
    const chain = useSelector((state) => state.userModal.chainId);
    const [formatedOrder, setFormatedOrder] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const getOrderId = (formatedOrder) => {
        const orderId = formatedOrder.id;
        return orderId;
    };

    const handlePutSuccess = () => {
        dispatch(setRecipient({}));
        dispatch(updateReadOrderInfo({}));
    };

    const handlePutOrder = async (orderId, body) => {
        try {
            console.log('orderId', orderId, 'body', body, 'walletAddress', walletAddress, 'chain.id', chain.id, 'signature', signature);
            await putOrder(orderId, body, walletAddress, chain.id, signature, handlePutSuccess);
            await sleep(1000); // wait for 1 second to make sure the order is updated
            setIsSuccess(true);
        } catch (e) {
            enqueueSnackbar('Error submitting order', {
                variant: 'error',
            });
        }
    }

    const fetchOrderData = async () => {
        try {
            const contractAddress = getDefaultContract();
            const ordersFromDBTmp = await getOrders(walletAddress, signature, contractAddress, chain.id, true);
            // const dataImage = await getMetaData(getDefaultWallet(), 0, "1155");
            if (ordersFromDBTmp) {
                console.log("ordeordersFromDBrs", ordersFromDBTmp);
                dispatch(updateOrdersFromDB(ordersFromDBTmp));
            }
        } catch (e) {
            console.log('error fetching order data', e);
        }
        dispatch(setSignature(''));
        handleSuccess();
        dispatch(updateOrderUpdateLoading(false));
    }

    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar('Recipient updated', {
                variant: 'success',
            });
            // get order again
            fetchOrderData();
        }
    }, [isSuccess]);

    // entry point of this put order
    useEffect(() => {
        if (readOrderInfo && readOrderInfo.id) {
            console.log('readOrderInfo', readOrderInfo);
            setFormatedOrder(formatReadOrder(readOrderInfo));
        }
    }, [readOrderInfo]);

    useEffect(() => {
        if (recipient && recipient.first_name && signature && formatedOrder && formatedOrder.id) {
            console.log('recipient', recipient, 'signature', signature, 'formatedOrder', formatedOrder);
            const orderId = getOrderId(formatedOrder);
            const body = {
                recipient
            }
            handlePutOrder(orderId, body);
        }
    }, [recipient, signature, formatedOrder]);

    return (
        <div className="post-order">

        </div>
    );
};