import { store } from "../app/store";
import { getProxyAvailable, getProxySupply } from "../contract_access_object/boxcat/readerCao";
import { getProxyMinted, getProxyName } from "../contract_access_object/readerCao";

export const getBoxCatInfo = async (info) => {
    // console.log("getBoxCatInfo", info);
    if (!info.contractAddr) {
        throw new Error("Invalid contract address.");
    }

    const userAddr = store.getState().userModal.walletAddress;
    const contractAddr = info.contractAddr;

    return Promise.allSettled([
        getProxyName(contractAddr),
        getProxyMinted(contractAddr),
        getProxySupply(contractAddr),
        getProxyAvailable(contractAddr, userAddr, 0),
        getProxyAvailable(contractAddr, userAddr, 1),
        getProxyAvailable(contractAddr, userAddr, 2)
    ]).then(([name, minted, supply, purchaseLimit0, purchaseLimit1, purchaseLimit2]) => {
        var nameRes = "";
        var mintedRes = "";
        var supplyRes = "";
        var purchaseLimit0Res = "";
        var purchaseLimit1Res = "";
        var purchaseLimit2Res = "";
        if (name.status === 'rejected') {
            console.log("xxxxx", name.reason);
        }
        else {
            nameRes = name.value;
        }

        if (minted.status === 'rejected') {
            console.log("xxxxx", minted.reason);
            mintedRes = 0;
        }
        else {
            mintedRes = Number(minted.value);
        }

        if (supply.status === 'rejected') {
            console.log("xxxxx", supply.reason);
            supplyRes = 0;
        }
        else {
            supplyRes = Number(supply.value);
        }

        if (purchaseLimit0.status === 'rejected') {
            console.log("xxxxx", purchaseLimit0.reason);
            purchaseLimit0Res = 0;
        }
        else {
            purchaseLimit0Res = Number(purchaseLimit0.value);
        }

        if (purchaseLimit1.status === 'rejected') {
            console.log("xxxxx", purchaseLimit1.reason);
            purchaseLimit1Res = 0;
        }
        else {
            purchaseLimit1Res = Number(purchaseLimit1.value);
        }

        if (purchaseLimit2.status === 'rejected') {
            console.log("xxxxx", purchaseLimit2.reason);
            purchaseLimit2Res = 0;
        }
        else {
            purchaseLimit2Res = Number(purchaseLimit2.value);
        }

        const available = [purchaseLimit0Res, purchaseLimit1Res, purchaseLimit2Res];
        console.log("available", available);
        const res = {
            ...info,
            name: nameRes,
            supply: supplyRes,
            minted: mintedRes,
            available,
        }
        return res;
    });
}