// This is a post order component that is used to put an order
// The entry point is txn, which is txn that confirm on blockchain
// When signature and recipient are ready, it will put new order to the database

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { setPostOrderBody, setRecipient, setSignature, updateOrderPostSuccess, updateOrderUpdateLoading } from "./commonSlice";
import { postOrder } from "../../dataLoader/dataLoader";

export const PostOrder = () => {
    const recipient = useSelector((state) => state.common.recipient);
    const postOrderBody = useSelector((state) => state.common.postOrderBody);
    const signature = useSelector((state) => state.common.signature);
    const walletAddress = useSelector((state) => state.userModal.walletAddress);
    const chain = useSelector((state) => state.userModal.chainId);

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();


    const handlePostSuccess = () => {
        dispatch(setRecipient({}));
        dispatch(setSignature(""));
        dispatch(setPostOrderBody({}));
        dispatch(updateOrderUpdateLoading(false));
        dispatch(updateOrderPostSuccess(true));
    };

    const handlePostOrder = async (body) => {
        try {
            console.log('body', body, 'walletAddress', walletAddress, 'chain.id', chain.id, 'signature', signature);
            await postOrder(body, walletAddress, chain.id, signature, handlePostSuccess);
        } catch (e) {
            enqueueSnackbar('Error submitting order', {
                variant: 'error',
            });
            dispatch(updateOrderUpdateLoading(false));
        }
    }

    // entry point of this put order
    useEffect(() => {
        if (recipient && recipient.first_name && signature && postOrderBody && postOrderBody.transaction_hash) {
            console.log('recipient', recipient, 'signature', signature, 'formatedOrder', postOrderBody);
            const body = {
                ...postOrderBody,
                recipient
            }
            handlePostOrder(body);
        }
    }, [recipient, signature, postOrderBody]);

    return (
        <div className="post-order">

        </div>
    );
};