import { setButtonText } from '../utils';
import { burnDispatchHub, getAttrInfo, getBurnInfoFromLoader } from '../dataLoader/dataLoader';

const checkSymbol = "burn-button"

export const updateBurnButton = () => {
    const burnButtons = [
        ...document.querySelectorAll(`[${checkSymbol}]`)
    ]
    if (burnButtons) {
        burnButtons.forEach((burnButton) => {
            const infoFromAttr = getAttrInfo(burnButton, checkSymbol);
            burnButton.href = "#"
            burnButton.onclick = async () => {
                const initialBtnText = burnButton.textContent;
                setButtonText(burnButton, "Loading...")
                // get info from data loader
                const infoFromLoader = await getBurnInfoFromLoader(infoFromAttr);
                // push info and dispath form open. 
                burnDispatchHub(infoFromLoader);
                // After dispatch
                setButtonText(burnButton, initialBtnText)
            }
        })
    }
}